import React from "react"
import $ from 'jquery';
class Header extends React.Component {
  componentDidMount() {
    // Collapse Navbar
    let navbarCollapse = function () {
      if ($("#mainNav").offset().top > 100) {
        $("#mainNav").addClass("navbar-shrink");
      } else {
        $("#mainNav").removeClass("navbar-shrink");
      }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);
    let links = Array.from(document.querySelectorAll('.js-scroll-trigger'));
    links.forEach(link => {
      link.addEventListener('click', (e)=> {
        e.preventDefault();
        const id = e.currentTarget?.href?.substring( e.currentTarget.href.indexOf('#'));
        document.querySelector(id).scrollIntoView({
          behavior: 'smooth'
        });
      })
    })
  }

  render() {
    const alertDismiss = ()=>{
      document.querySelector('.alert.alert-info').remove();
    }
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
          
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">
              {'Burkhard Kunkel'}
            </a>
            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
              data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
              aria-label="Toggle navigation">
              Menu
                  <i className="fas fa-bars ml-1"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav text-uppercase ml-auto">
                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#services">Leistungen</a></li>
                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#konzept">Konzept</a></li>
                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#team">Team</a></li>
                <li className="nav-item"><a className="nav-link js-scroll-trigger" data-toggle="modal" href="#karriere">Jobs</a></li>
                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#contact">Kontakt</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <header className="masthead">
          <div className="container">
            <div className="masthead-subheading">Steuerberater Diplomkaufmann Burkhard Kunkel in Berlin</div>
            <div className="masthead-heading text-uppercase">Herzlich Willkommen!</div>
            <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">Leistungen</a>
          </div>
          <img className="masthead-logo" src="assets/Logo.png"></img>
          <img className="masthead-award" src="assets/award.png"></img>
        </header>
        <div className="masthead-award-mobile">
          <img src="assets/award.png"></img>
        </div>
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          <strong>Hallöchen!</strong>&nbsp;<span> Der nächste Termin ist in 29 Tagen! Hier finden Sie </span> <strong><a target="_blank" style={{color: '#0c5460'}} href="https://calendar.google.com/calendar/u/0/r?cid=NTh0M2Vkb2ltdDBrNWtocjRuMjRqcDU0bjBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ">den gesamten Steuerkalendar!</a></strong>
          <button type="button" onClick={alertDismiss} className="close" data-bs-dismiss="alert" aria-label="Zurück">x</button>
        </div>
      </>
    )
  }
}

export default Header
