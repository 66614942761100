import React from "react"

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer py-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 text-lg-left">© Diplomkaufmann Burkhard Kunkel Steuerberater</div>
                        <div className="col-lg-4 my-3 my-lg-0">
                            <a className="btn btn-dark btn-social mx-2" href="mailto:info@steuerberater-kunkel.de"><i className="fas fa-envelope-square"></i></a>
                            <a className="btn btn-dark btn-social mx-2" target="_blank" href="https://www.facebook.com/Steuerberater.Kunkel"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-dark btn-social mx-2" href="tel:+493021000630"><i className="fas fa-phone-alt"></i></a>
                        </div>
                        <div className="col-lg-4 text-lg-right">
                            <a data-toggle="modal" href="#interessante_links">Interessante Links</a> |
                            <a data-toggle="modal" href="#impressum">Impressum</a>|<span> </span>
                            <a data-toggle="modal" href="#mandatenbrief">Mandantenbrief</a>|<span> </span>
                            <a data-toggle="modal" href="#aabs">AAB's</a>
                        </div>
                    </div>
                </div>
            </footer>)
    }
}

export default Footer;